import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ACCOUNT_OF_USER, LIST_ACCOUNTS_WITHOUT_USER } from '../queries/gql';
import { ADD_ACCOUNT_TO_USER, REMOVE_ACCOUNT_FROM_USER } from '../mutations/gql';

function UserAccountManagement({ userEmail, groupName, selectedOuId }) {
    // Query for getting the user's current account
    const { loading, error, data, refetch } = useQuery(GET_ACCOUNT_OF_USER, {
        variables: { userEmail, groupName },
    });

    // Query for listing accounts within the selected OU without users assigned
    const { loading: loadingAccounts, error: errorAccounts, data: accountsData, refetch: refetchAccounts } = useQuery(LIST_ACCOUNTS_WITHOUT_USER, {
        variables: { ouId: selectedOuId },
        skip: !selectedOuId, // Skip the query if no OU is selected
    });

    // Mutation for adding an account to a user
    const [addAccountToUser] = useMutation(ADD_ACCOUNT_TO_USER, {
        onCompleted: () => {
            refetch(); // Refetch the account data for the user after adding
            refetchAccounts(); // Refetch the list of available accounts
        },
    });

    // Mutation for removing an account from a user
    const [removeAccountFromUser] = useMutation(REMOVE_ACCOUNT_FROM_USER, {
        onCompleted: () => {
            refetch(); // Refetch the account data for the user after removal
            refetchAccounts(); // Refetch the list of available accounts
        },
    });

    const [showAddAccountForm, setShowAddAccountForm] = useState(false);

    const handleAddAccount = () => {
        setShowAddAccountForm(true);
    };

    const handleSelectAccount = (accountId) => {
        addAccountToUser({ variables: { userEmail, groupName, accountId } });
        setShowAddAccountForm(false);
    };

    const handleRemoveAccount = () => {
        if (data && data.getAccountOfUser) {
            removeAccountFromUser({ variables: { userEmail, groupName, accountId: data.getAccountOfUser } });
        }
    };

    if (loading) return <li>{userEmail} - Loading account...</li>;
    if (error) return <li>{userEmail} - Error fetching account</li>;

    return (
        <div className="table-row">
            <div className="table-cell-fix-L">{userEmail}</div>
            <div className="table-cell">
                {data.getAccountOfUser ? (
                    <>
                        <div className="table-cell-fix-M">
                            Account: {data.getAccountOfUser}
                        </div>
                        <div className="table-cell-fix-M">
                            <button onClick={handleRemoveAccount}>Remove Account</button>
                        </div>
                    </>
                ) : (
                        <>
                            <div className="table-cell-fix-M">
                                No account available
                            </div>
                            <div className="table-cell-fix-M">
                                <button onClick={handleAddAccount}>Add Account</button>
                            </div>
                            {showAddAccountForm && (
                                <div>
                                    {loadingAccounts ? (
                                        <p>Loading available accounts...</p>
                                    ) : errorAccounts ? (
                                        <p>Error loading accounts</p>
                                    ) : accountsData && accountsData.listAccountsWithoutUser?.length > 0 ? (
                                        <select onChange={(e) => handleSelectAccount(e.target.value)}>
                                            <option value="">Select an account</option>
                                            {accountsData.listAccountsWithoutUser.map(({ accountId, accountName }) => (
                                                <option key={accountId} value={accountId}>
                                                    {accountName} (ID: {accountId})
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        <p>No available accounts in this OU.</p>
                                    )}
                                </div>
                            )}
                    </>
                )}
            </div>
        </div>
    );

}

export default UserAccountManagement;
