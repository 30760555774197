import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { LIST_USERS_OF_GROUP, GET_OUS_OF_GROUP, LIST_ORGANIZATION_UNITS } from '../queries/gql';
import { ADD_OU_TO_GROUP, REMOVE_OU_FROM_GROUP, ADD_USER_TO_GROUP } from '../mutations/gql';
import UserAccountManagement from './UserAccountManagement';

function UsersInGroupList() {
    const { groupName } = useParams();
    const [showAssignOu, setShowAssignOu] = useState(false);
    const [selectedOu, setSelectedOu] = useState('');
    const [currentOuId, setCurrentOuId] = useState('');
    const [userEmail, setUserEmail] = useState('');

    const { loading: loading_users, error: error_users, data: data_users, refetch: refetchUsers } = useQuery(LIST_USERS_OF_GROUP, {
        variables: { groupName },
    });

    const { loading: loading_ous_of_group, error: error_ous_of_group, data: data_ous_of_group, refetch: refetchOusOfGroup } = useQuery(GET_OUS_OF_GROUP, {
        variables: { groupName },
        onCompleted: (data) => {
            if (data?.getOusOfGroup?.length > 0) {
                setCurrentOuId(data.getOusOfGroup[0].ouId);
            }
        },
    });

    const { loading: loading_ous, error: error_ous, data: data_ous } = useQuery(LIST_ORGANIZATION_UNITS);

    const [addOuToGroup] = useMutation(ADD_OU_TO_GROUP, {
        onCompleted: () => {
            refetchOusOfGroup();
            setCurrentOuId(''); 
        },
    });

    const [removeOuFromGroup] = useMutation(REMOVE_OU_FROM_GROUP, {
        onCompleted: () => {
            refetchOusOfGroup();
            setCurrentOuId(''); 
        },
    });

    const [addUserToGroup] = useMutation(ADD_USER_TO_GROUP, {
        onCompleted: () => {
            refetchUsers(); 
            setUserEmail(''); 
        },
    });

    const handleAssignOu = () => {
        if (!selectedOu) return;
        addOuToGroup({ variables: { groupName, ouId: selectedOu } })
            .then(() => setShowAssignOu(false))
            .catch(err => console.error("Error assigning OU:", err));
    };

    const handleRemoveOu = (ouId) => {
        removeOuFromGroup({ variables: { groupName, ouId } }).catch(err => console.error("Error removing OU:", err));
    };

    const handleSelectOu = (ouId) => {
        setCurrentOuId(ouId);
    };

    const handleAddUserToGroup = () => {
        if (userEmail.trim() === '') return;
        addUserToGroup({ variables: { userEmail, groupName } }).catch(err => console.error("Error adding user to group:", err));
    };

    if (loading_users || loading_ous_of_group) return <p>Loading...</p>;
    if (error_users || error_ous_of_group) return <p>Error: {error_users?.message || error_ous_of_group?.message}</p>;

    const assignedOus = data_ous_of_group?.getOusOfGroup ?? [];

    return (
        <div>
            <h3>Users in group: {groupName}</h3>

            {/* Assigned OUs */}
            <div className="table-like">
                {assignedOus.length > 0 ? (
                    assignedOus.map(({ ouId, ouName }) => (
                        <div key={`${groupName}-${ouId}`} className="table-row">
                            <div className="select-column">
                                <input
                                    type="radio"
                                    name="ouSelection"
                                    value={ouId}
                                    checked={currentOuId === ouId}
                                    onChange={() => handleSelectOu(ouId)}
                                />
                            </div>
                            <div className="table-cell">
                                {ouName} ({ouId})
                            </div>
                            <div className="table-cell">
                                <button onClick={() => handleRemoveOu(ouId)}>Remove OU</button>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="table-row">
                        <div className="table-cell" colSpan="3">
                            <button onClick={() => setShowAssignOu(!showAssignOu)}>
                                {showAssignOu ? 'Cancel' : 'Assign OU'}
                            </button>
                            {showAssignOu && (
                                <div>
                                    <select value={selectedOu} onChange={e => setSelectedOu(e.target.value)}>
                                        <option value="">Select an OU</option>
                                        {loading_ous ? (
                                            <option>Loading OUs...</option>
                                        ) : error_ous ? (
                                            <option>Error loading OUs</option>
                                        ) : (
                                            data_ous?.listOrganizationUnits.map(ou => (
                                                <option key={ou.ouId} value={ou.ouId}>
                                                    {ou.ouName}
                                                </option>
                                            ))
                                        )}
                                    </select>
                                    <button onClick={handleAssignOu} disabled={!selectedOu}>
                                        Confirm Assign
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>

            {/* Add User */}
            <h4>Add a user to this group:</h4>
            <div className="table-like">
                <div className="table-row">
                    <div className="table-cell">
                        <input
                            type="email"
                            value={userEmail}
                            onChange={(e) => setUserEmail(e.target.value)}
                            placeholder="Enter user email"
                        />
                    </div>
                    <div className="table-cell">
                        <button onClick={handleAddUserToGroup} disabled={!userEmail.trim()}>
                            Add User to Group
                        </button>
                    </div>
                </div>
            </div>

            {/* List Users */}
            <h4>Users in this group:</h4>
            <div className="table-like">
                {data_users && data_users.listUsersOfGroup.length > 0 ? (
                    data_users.listUsersOfGroup.map(({ userEmail }) => (
                        <div key={userEmail} className="table-row">
                            <UserAccountManagement 
                                userEmail={userEmail} 
                                groupName={groupName} 
                                selectedOuId={currentOuId} 
                            />
                        </div>
                    ))
                ) : (
                    <div className="table-row"><div className="table-cell" colSpan="2">No users found.</div></div>
                )}
            </div>
        </div>
    );
}

export default UsersInGroupList;
