import React, { useState } from 'react';
import { withAuthenticator, Button } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './config/aws-exports';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import AddUsersToGroup from './component/AddUsersToGroup';
import RemoveUsersFromGroup from './component/RemoveUsersFromGroup';
import UsersInGroupList from './component/UsersInGroupList';
import GameScore from './component/GameScore';
import Home from './component/Home';

function importAll(r) {
  r.keys().forEach(r);
}

importAll(require.context('./css/', true, /\.css$/));

Amplify.configure({
  Auth: {
    Cognito: {
      region: awsExports.REGION,
      userPoolId: awsExports.USER_POOL_ID,
      userPoolClientId: awsExports.USER_POOL_APP_CLIENT_ID,
      loginWith: {
        email: true,
        name: true,
      }
    }
  }
});

function AppContent({ signOut, user }) {
  const [showHeader, setShowHeader] = useState(false);
  const navigate = useNavigate();

  const toggleHeader = () => {
    setShowHeader(!showHeader);
  };

  const goHome = () => {
    navigate('/');
  };

  return (
    <div>
      <Button onClick={toggleHeader}>
        👤
      </Button>

      <Button onClick={goHome}>
        🏠
      </Button>
      
      {showHeader && (
        <div className="table-like">
          <div className="table-row">
            <div className="table-cell">
              Admin: {user ? user.username : 'Loading...'}
            </div>
            <div className="table-cell">
              <Button onClick={signOut}>Sign out</Button>
            </div>
          </div>
        </div>
      )}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/add-users-to-group/:groupName" element={<AddUsersToGroup />} />
        <Route path="/remove-users-from-group/:groupName" element={<RemoveUsersFromGroup />} />
        <Route path="/list-users-in-group/:groupName" element={<UsersInGroupList />} />
        <Route path="/game-score/:groupName" element={<GameScore />} />
      </Routes>
    </div>
  );
}

const App = ({ signOut, user }) => {
  return (
    <Router>
      <AppContent signOut={signOut} user={user} />
    </Router>
  );
};

export default withAuthenticator(App);