import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { LIST_USERS_OF_GROUP, LIST_GAME_USER_SCORES, GET_GAME_GROUP_STATUS } from '../queries/gql';
import { SET_GAME_GROUP_STATUS } from '../mutations/gql';

function UsersInGroupList() {
    const { groupName } = useParams();
    const [gameGroupStatus, setGameGroupStatus] = useState(false);

    const { loading: loading_users, error: error_users, data: data_users } = useQuery(LIST_USERS_OF_GROUP, {
        variables: { groupName },
    });

    const { loading: loading_scores, error: error_scores, data: data_scores } = useQuery(LIST_GAME_USER_SCORES, {
        variables: { groupName },
    });

    const { loading: loading_game_group_status, error: error_game_group_status, data: data_game_group_status, refetch: refetchStatus } = useQuery(GET_GAME_GROUP_STATUS, {
        variables: { groupName },
        fetchPolicy: 'network-only'
    });

    const [setGameGroupStatusMutation] = useMutation(SET_GAME_GROUP_STATUS, {
        onCompleted: () => {
            // Refetch the status after mutation to ensure we have the latest state
            refetchStatus();
        },
    });

    // Use effect to update state when data for game status changes
    useEffect(() => {
        if (data_game_group_status && data_game_group_status.getGameGroupStatus !== undefined) {
            setGameGroupStatus(data_game_group_status.getGameGroupStatus);
        }
    }, [data_game_group_status]);

    if (loading_users || loading_scores || loading_game_group_status) return <p>Loading...</p>;
    if (error_users || error_scores || error_game_group_status) {
        return <p>Error: {error_users?.message || error_scores?.message || error_game_group_status.message}</p>;
    }

    const mergedData = data_users?.listUsersOfGroup.map(user => {
        const scoreObj = data_scores.listGameUserScores.find(score => score.userEmail === user.userEmail);
        return {
            ...user,
            totalScore: scoreObj ? scoreObj.totalScore : 0
        };
    }).sort((a, b) => b.totalScore - a.totalScore);

    return (
        <div>
            <h3>Game { gameGroupStatus ? 'enabled' : 'disabled'}</h3>
            <button onClick={() => setGameGroupStatusMutation({ variables: { groupName, status: !gameGroupStatus } })}>
                {gameGroupStatus ? 'Disable' : 'Enable'}
            </button>
            
            <h3>{groupName} Score</h3>
            <div className="table-like">
                <div className="table-row header">
                    <div className="table-cell">Position</div>
                    <div className="table-cell">User Name</div>
                    <div className="table-cell">Score</div>
                </div>
                {mergedData && mergedData.length > 0 ? (
                    mergedData.map((user, index) => (
                        <div key={user.userEmail} className="table-row">
                            <div className="table-cell">{index + 1}</div>
                            <div className="table-cell">{user.userName}</div>
                            <div className="table-cell">{user.totalScore}</div>
                        </div>
                    ))
                ) : (
                    <div className="table-row"><div className="table-cell" colSpan="3">No users found.</div></div>
                )}
            </div>
        </div>
    );
}

export default UsersInGroupList;