import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { LIST_USERS_WITHOUT_GROUP, LIST_GROUPS } from '../queries/gql';
import { ADD_USER_TO_GROUP } from '../mutations/gql';

function UsersList() {
    const { loading: loadingUsersWithoutGroup, error: errorUsersWithoutGroup, data: dataUsersWithoutGroup, refetch: refetchUsersWithoutGroup } = useQuery(LIST_USERS_WITHOUT_GROUP);

    const { loading: loadingGroups, error: errorGroups, data: dataGroups } = useQuery(LIST_GROUPS);

    const [addUserToGroup] = useMutation(ADD_USER_TO_GROUP, {
        onCompleted: () => {
            refetchUsersWithoutGroup(); 
        },
    });

    const [showAddGroupForm, setShowAddGroupForm] = useState({});

    const handleAddToGroup = (userEmail) => {
        setShowAddGroupForm((prevState) => ({ ...prevState, [userEmail]: true }));
    };

    const handleSelectGroup = (userEmail, groupName) => {
        addUserToGroup({ variables: { userEmail, groupName } });
        setShowAddGroupForm((prevState) => ({ ...prevState, [userEmail]: false }));
    };

    if (loadingUsersWithoutGroup) return <p>Loading users without group...</p>;
    if (errorUsersWithoutGroup) return <p>Error loading users: {errorUsersWithoutGroup.message}</p>;

    return (
        <div>
            <h3>Users without group:</h3>
            <div className="table-like">
                <div className="table-row header">
                    <div className="table-cell">User Email</div>
                    <div className="table-cell">Actions</div>
                </div>
                {dataUsersWithoutGroup && dataUsersWithoutGroup.listUsersWithoutGroup && dataUsersWithoutGroup.listUsersWithoutGroup.length > 0 ? (
                    dataUsersWithoutGroup.listUsersWithoutGroup.map(({ userEmail }) => (
                        <div key={userEmail} className="table-row">
                            <div className="table-cell">{userEmail}</div>
                            <div className="table-cell">
                                <button className="button" onClick={() => handleAddToGroup(userEmail)}>Add to Group</button>
                                {showAddGroupForm[userEmail] && (
                                    <div>
                                        {loadingGroups ? (
                                            <p>Loading groups...</p>
                                        ) : errorGroups ? (
                                            <p>Error loading groups: {errorGroups.message}</p>
                                        ) : (
                                            <select onChange={(e) => handleSelectGroup(userEmail, e.target.value)}>
                                                <option value="">Select a group</option>
                                                {dataGroups.listGroups.map(({ groupName }) => (
                                                    <option key={groupName} value={groupName}>
                                                        {groupName}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="table-row"><div className="table-cell" colSpan="2">No users found.</div></div>
                )}
            </div>
        </div>
    );
}

export default UsersList;