import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
    LIST_GROUPS
} from '../queries/gql';

function GroupsList() {
    const { loading: loading_groups, error: error_groups, data: data_groups } = useQuery(LIST_GROUPS);

    if (loading_groups) return <p>Loading...</p>;
    if (error_groups) {
        return (
            <p>
                Error :( {error_groups ? error_groups.message : ''}
            </p>
        );
    }

    return (
        <div>
            <h3>Groups:</h3>
            <div className="table-like">
                <div className="table-row header">
                    <div className="table-cell">Group Name</div>
                    <div className="table-cell">Actions</div>
                </div>
                {data_groups?.listGroups?.map(({ groupName }) => (
                    <div key={groupName} className="table-row">
                        <div className="table-cell">{groupName}</div>
                        <div className="table-cell">
                            <Link to={`/add-users-to-group/${groupName}`} className="button-link">Add Users</Link>
                            <Link to={`/remove-users-from-group/${groupName}`} className="button-link">Remove Users</Link>
                            <Link to={`/list-users-in-group/${groupName}`} className="button-link">Manage Group and Labs</Link>
                            {groupName.startsWith('NetworkingGameDay') && (
                                <Link to={`/game-score/${groupName}`} className="button-link">Manage Game</Link>
                            )}
                        </div>
                    </div>
                ))}
                {(!data_groups?.listGroups || data_groups.listGroups.length === 0) && (
                    <div className="table-row">
                        <div className="table-cell" colSpan="2">No groups found.</div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default GroupsList;