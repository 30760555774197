import { gql } from '@apollo/client';

export const LIST_USERS = gql`
  query ListUsers($limit: Int, $nextToken: String) {
    listUsers(limit: $limit, nextToken: $nextToken) {
      items {
        userEmail
      }
      nextToken
    }
  }
`;

export const LIST_USERS_OF_GROUP = gql`
  query ListUsersOfGroup($groupName: String!) {
    listUsersOfGroup(groupName: $groupName) {
      userEmail
      userName
    }
  }
`;


export const LIST_ALL_USERS_AND_THEIR_GROUPS = gql`
  query ListAllUsersAndTheirGroups {
    listAllUsersAndTheirGroups {
      userEmail
      groupNames
    }
  }
`;

export const LIST_ALL_USERS_AND_THEIR_ACCOUNTS = gql`
  query ListAllUsersAndTheirAccounts {
    listAllUsersAndTheirAccounts {
      userEmail
      accountId
    }
  }
`;

export const LIST_GROUPS = gql`
  query ListGroups {
    listGroups {
      groupName
    }
  }
`;

export const LIST_ALL_GROUPS_AND_THEIR_USERS = gql`
  query ListAllGroupsAndTheirUsers {
    listAllGroupsAndTheirUsers {
      groupName
      userEmails
    }
  }
`;

export const LIST_USERS_WITHOUT_GROUP = gql`
  query ListUsersWithoutGroup {
    listUsersWithoutGroup {
      userEmail
    }
  }
`;

export const LIST_USERS_WITHOUT_ACCOUNT = gql`
  query ListUsersWithoutAccount {
    listUsersWithoutAccount {
      userEmail
    }
  }
`;

export const LIST_GROUPS_WITHOUT_USER = gql`
  query ListGroupsWithoutUser {
    listGroupsWithoutUser {
      groupName
    }
  }
`;

export const LIST_ACCOUNTS = gql`
  query ListAccounts {
    listAccounts {
      accountId
      accountName
    }
  }
`;

export const LIST_ACCOUNTS_WITHOUT_USER = gql`
  query ListAccountsWithoutUser($ouId: String!) {
    listAccountsWithoutUser(ouId: $ouId) {
      accountId
      accountName
    }
  }
`;


export const FETCH_USERS_NOT_IN_GROUP = gql`
  query FetchUsersNotInGroup($groupName: String!) {
    fetchUsersNotInGroup(groupName: $groupName) {
      userEmail
    }
  }
`;

export const GET_ACCOUNT_OF_USER = gql`
  query GetAccountOfUser($userEmail: String!, $groupName: String!) {
    getAccountOfUser(userEmail: $userEmail, groupName: $groupName)
  }
`;


export const LIST_ORGANIZATION_UNITS = gql`
  query ListOrganizationUnits {
    listOrganizationUnits {
      ouId
      ouName
    }
  }
`;

export const GET_OUS_OF_GROUP = gql`
  query GetOusOfGroup($groupName: String!) {
    getOusOfGroup(groupName: $groupName) {
      ouId
      ouName
    }
  }
`;

export const GET_GAME_GROUP_STATUS = gql`
  query getGameGroupStatus($groupName: String!) {
    getGameGroupStatus(groupName: $groupName)
  }
`;

export const LIST_ACCOUNTS_BY_OU_ID = gql`
  query ListAccountsByOuId($ouId: String!) {
    listAccounts(ouId: $ouId) {
      accountId
      accountName
    }
  }
`;

export const LIST_GAME_USER_SCORES = gql`
  query ListGameUserScores($groupName: String!) {
    listGameUserScores(groupName: $groupName) {
      userEmail
      totalScore
    }
  }
`;

