import { gql } from '@apollo/client';

export const REMOVE_ACCOUNT_FROM_USER = gql`
  mutation RemoveAccountFromUser($userEmail: String!, $groupName: String!, $accountId: String!) {
    removeAccountFromUser(userEmail: $userEmail, groupName: $groupName, accountId: $accountId)
  }
`;

export const ADD_USER_TO_GROUP = gql`
  mutation AddUserToGroup($userEmail: String!, $groupName: String!) {
    addUserToGroup(userEmail: $userEmail, groupName: $groupName)
  }
`;

export const REMOVE_USER_FROM_GROUP = gql`
  mutation RemoveUserFromGroup($userEmail: String!, $groupName: String!) {
    removeUserFromGroup(userEmail: $userEmail, groupName: $groupName)
  }
`;

export const ADD_ACCOUNT_TO_USER = gql`
  mutation AddAccountToUser($userEmail: String!, $groupName: String!, $accountId: String!) {
    addAccountToUser(userEmail: $userEmail, groupName: $groupName, accountId: $accountId)
  }
`;

export const ADD_OU_TO_GROUP = gql`
  mutation AddOuToGroup($groupName: String!, $ouId: String!) {
    addOuToGroup(groupName: $groupName, ouId: $ouId)
  }
`;

export const REMOVE_OU_FROM_GROUP = gql`
  mutation RemoveOuFromGroup($groupName: String!, $ouId: String!) {
    removeOuFromGroup(groupName: $groupName, ouId: $ouId)
  }
`;

export const SET_GAME_GROUP_STATUS = gql`
  mutation SetGameGroupStatus($groupName: String!, $status: Boolean!) {
    setGameGroupStatus(groupName: $groupName, status: $status)
  }
`;